<template>
  <div id="dashboard-content">
    <h4 class="font-weight-bold mb-2">Pilih Kurikulum</h4>
    <p>
      Harap untuk pastikan profil telah dilengkapi. Kemudian pilih izin
      berdasarkan kurikulum dibawah ini :
    </p>
    <div class="row mt-5">
      <div class="col-lg-4">
        <router-link
          to="/user/izin-cetak/merdeka"
          class="d-flex align-items-center bg-primary-50 position-relative rounded hover"
          style="padding: 60px 20px;"
        >
          <h4 class="text-grey-600">Kurikulum Merdeka</h4>
          <img
            src="@/assets/dashboard/icon/Izin Cetak Merdeka.png"
            class="position-absolute"
            alt="Icon"
            style="bottom: 0;right: 0;"
          />
        </router-link>
      </div>
      <div class="col-lg-4">
        <router-link
          to="/user/izin-cetak/k13"
          class="d-flex align-items-center bg-secondary-50 position-relative rounded hover"
          style="padding: 60px 20px;"
        >
          <h4 class="text-grey-600">Kurikulum K-13</h4>
          <img
            src="@/assets/dashboard/icon/Izin Cetak K13.png"
            class="position-absolute"
            alt="Icon"
            style="bottom: 0;right: 0;"
          />
        </router-link>
      </div>
      <div class="col-lg-4">
        <div
          class="d-flex align-items-center bg-primary-50 position-relative rounded hover"
          style="padding: 60px 20px;"
        >
          <h4 class="text-grey-600">Non-teks</h4>
          <img
            src="@/assets/dashboard/icon/Izin Cetak Nonteks.png"
            class="position-absolute"
            alt="Icon"
            style="bottom: 0;right: 0;"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Izin Cetak",
  created() {
    const logged_in = localStorage.getItem("token");
    if (logged_in) {
      const { role_name } = JSON.parse(localStorage.getItem("user"));
      if (role_name === "Penerbit" || role_name === "Pencetak") {
        return;
      } else {
        this.$router.push("/user/home");
      }
    } else {
      this.$router.push("/login");
    }
  },
};
</script>
